import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import { BareFetcher, PublicConfiguration } from 'swr/_internal';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import { ApiError, ApiResponseAsync, isApiError } from '../container/models';
import { useApplicationErrorHandling } from '../hooks/useApplicationErrorHandling';
import { ApplicationError } from '../types/errorHandling';
import { ApiActions } from './actions';
import { authCache } from './cache';
import { UseAPIResult, UseReadAPIResult } from './useAPI';

type ServicesCacheKey = undefined | null | string | any[];

export const refreshIntervalInConfigModeInSeconds = 10;

function isString(value: any): value is string {
  return typeof value === 'string';
}

function buildCacheKeyFromArray(value: any[]): string[] | undefined {
  const undefinedElementIndex = value.findIndex((item) => !item);
  if (undefinedElementIndex >= 0) return undefined;
  return value.map((item) => {
    if (isString(item)) return item;
    if (item.hasOwnProperty('id')) {
      return item['id'] as string;
    }
    return JSON.stringify(item);
  });
}

function buildCacheKey(key: ServicesCacheKey): undefined | string | string[] {
  if (!key) return undefined;
  if (isString(key)) return key;
  return buildCacheKeyFromArray(key);
}

export type WriteAPIWithServicesResponse<Payload, Response> =
  UseAPIResult<Response> & {
    isExecuting: boolean;
    trigger: [Payload] extends [never]
      ? () => Promise<Response>
      : (payload: Payload) => Promise<Response>;
    reset: () => void;
  };

const timeSecond = 1000;
const timeMinute = 60 * timeSecond;
const timeHour = 60 * timeMinute;

export enum ReadingCacheType {
  Static = 'static',
  /** focal point of the configuration page (like get fields for fields configuration page) */
  ConfigurationMainEntity = 'configuration_main_entity',
  /** dependencies of the focal point (like get process draft for the fields configuration page) */
  ConfigurationDependency = 'configuration_dependency',
  /** focal point of the execution page (like get instances for list of instances page) */
  ExecutionMainEntity = 'execution_main_entity',
  /** dependencies of the focal point which are related to process release (like indexing scenario) */
  ExecutionReleaseDependency = 'execution_release_dependency',
  /** dependencies of the focal point which are related to process draft (like process aliases) */
  ExecutionDraftDependency = 'execution_draft_dependency',
}

function buildCacheConfigurationFromCacheType(
  cacheType: ReadingCacheType
): Pick<
  Partial<PublicConfiguration>,
  'dedupingInterval' | 'revalidateOnMount'
> {
  switch (cacheType) {
    case ReadingCacheType.Static:
      return {
        dedupingInterval: 24 * timeHour,
      };
    case ReadingCacheType.ConfigurationMainEntity:
      return {
        revalidateOnMount: true,
      };
    case ReadingCacheType.ConfigurationDependency:
      return {
        dedupingInterval: 5 * timeMinute,
      };
    case ReadingCacheType.ExecutionMainEntity:
      return {
        revalidateOnMount: true,
      };
    case ReadingCacheType.ExecutionReleaseDependency:
      return {
        dedupingInterval: timeHour,
      };
    case ReadingCacheType.ExecutionDraftDependency:
      return {
        dedupingInterval: 9 * timeMinute,
      };
    default:
      throw new Error(`unsupported cache type ${cacheType}`);
  }
}

/**
 * useReadAPIWithServices wrapper around action using SWR;
 * key is usually array that starts with globally unique action name and
 * includes all action dependencies; if dependency is some object with id then it's replaced internally with
 * a value of the property; if at least one member of array is undefined then key passed to SWR will be also be undefined
 *
 * A method to get information from the server and store it within cache.
 * @param key an identifier for the piece of information you're storing in cache, if this is not defined on call, then
 * a call to the server will be made and then stored in the cache under this name. If it is found, then it returns the
 * value associated with this key. Every property you pass to action should appear in the key.
 * @param action a function that returns a response, i.e., search, get, etc
 * @param opts optional parameters
 */
export const useReadAPIWithServices = <Response>(
  /** Either string or array of action dependencies. */
  key: ServicesCacheKey,

  /** Action being wrapped by SWR. */
  action: () => ApiResponseAsync<Response>,

  opts: {
    cacheType: ReadingCacheType;
  }
): UseReadAPIResult<Response> => {
  const handleApplicationError = useApplicationErrorHandling();
  const history = useHistory();
  const dispatch = useDispatch();

  const swrKey = buildCacheKey(key);

  // define a fetcher; handle application errors by wrapping them in a rejected promise
  const fetcher = async () => {
    const response = await action();
    return await (isApiError(response)
      ? Promise.reject(response)
      : Promise.resolve(response));
  };

  const cacheConfig = buildCacheConfigurationFromCacheType(opts.cacheType);
  // define config that provides error handling by default
  const config = useMemo(
    (): Partial<
      PublicConfiguration<Response, ApiError, BareFetcher<Response>>
    > => ({
      onError(err, key) {
        handleApplicationError(
          ApplicationError.FromError(err, `Failed to fetch ${key}`)
        );
        // Move to log in screen if 401 Unauthorized response from server - clears caches
        if (err.status === 401) {
          dispatch(ApiActions.signedOut()); // redux cache
          authCache.clear(); // browser cache
          history.push('/');
        }
      },

      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,

      ...cacheConfig,

      //  revalidateOnMount: true,

      // dedupingInterval: opts?.lifespan,
      // focusThrottleInterval: opts?.lifespan,
    }),
    [cacheConfig, dispatch, handleApplicationError, history]
  );

  return useSWR<Response, ApiError>(swrKey, fetcher, config);
};

/**
 * Sends information to the server. The Payload is the business data going in, and Response is the business
 * data going out.
 * @param key uniquely identify the request. Typically comprised of all parameters used in the action
 * @param action what you want to do with the server
 * @param opts optional parameters
 */
export const useWriteAPIWithServices = <Payload, Response>(
  /** Either string or array of action dependencies. */
  key: ServicesCacheKey,

  /** Action being wrapped by SWR. */
  action: (payload: Payload) => ApiResponseAsync<Response>,

  /** Additional options. */
  opts?: {
    /** Function to call when a request succeeds. One use for this is for calling a {@link useReadAPI} `mutate` function to tell it to update. */
    onSuccess?: (data: Response) => void;
  }
): WriteAPIWithServicesResponse<Payload, Response> => {
  const handleApplicationError = useApplicationErrorHandling();

  const swrKey = buildCacheKey(key);

  const fetcher = useCallback(
    (_: string, { arg }: { arg: Payload }) =>
      action(arg).then((response) =>
        isApiError(response)
          ? Promise.reject(response)
          : Promise.resolve(response)
      ),
    [action]
  );

  const config = useMemo(
    (): Partial<
      SWRMutationConfiguration<Response, ApiError, ServicesCacheKey, Payload>
    > => ({
      onError(error: ApiError, key: string) {
        handleApplicationError(
          ApplicationError.FromError(error, `Failed to write ${key}`)
        );
      },
      onSuccess(data) {
        opts?.onSuccess?.(data);
      },
    }),
    [handleApplicationError, opts]
  );

  const { isMutating, data, error, trigger, reset } = useSWRMutation<
    Response,
    ApiError,
    ServicesCacheKey,
    Payload
  >(swrKey, fetcher, config);

  return {
    isExecuting: isMutating,
    data,
    error,
    trigger,
    reset,
  };
};
