import { UserTenant } from 'api/endpoints/users';
import { WithTenantId, WithUserId } from 'api/types/pathParams';

import ApiUrlParams from 'constants/apiUrlParams';

import { excludeObjectProperty } from 'utils/normalizers/object';

import { API_REQ_ACTION_TYPES } from '../actions';
import apiReqSagaCreator from '../apiReqSagaCreator';
import { ApiPaths, HTTP_METHODS } from '../constants';

export type OrganizationStatus = 'active';

export type TenantRole = 'tenant_admin' | 'tenant_manager' | 'tenant_user';

export interface TenantGeneral extends BaseEntity {
  organization: Organization;
}

export interface Permissions {
  ocr?: boolean;
  e2Open?: boolean;
}

export interface Organization extends BaseEntity {
  email: string;
  features: Permissions;
  licenses: number;
  phone: number;
  status: OrganizationStatus;
  replyTo: string;
}

export interface UserData {
  userId: string;
  tenantRole: TenantRole;
  permissions: KeyValuePairs<boolean>;
}

export interface AddUserSToTenant {
  body: {
    users: UserData[];
  };
}

export interface UpdateUserInTenant {
  body: {
    tenantRole: TenantRole;
    permissions: KeyValuePairs<boolean>;
  };
}

export interface TenantData {
  name: string;
}

export type GetTenantsParams = Pagination | undefined;
export type GetTenantsResult = DataWithTotal<TenantGeneral>;
export type AddUserToTenantParams = AddUserSToTenant & WithTenantId;
export type GetUserToTenantResult = unknown;
export type RemoveUserFromTenantParams = WithUserId & WithTenantId;
export type RemoveUserFromTenantResult = unknown;
export type UpdateUserInTenantParams = UpdateUserInTenant &
  WithUserId &
  WithTenantId;
export type UpdateUserInTenantResult = unknown;
export type AddTenantResult = TenantGeneral;
export type AddTenantParams = TenantData;
export type UpdateTenantParams = TenantData & WithTenantId;
export type UpdateTenantResult = TenantGeneral;
export type DeleteTenantParams = WithTenantId;
export type DeleteTenantResult = unknown;
export type GetUserTenantsParams = WithUserId;
export type GetUserTenantsResult = UserTenant[];

const Tenants = {
  getTenants: apiReqSagaCreator<GetTenantsParams, GetTenantsResult>(
    API_REQ_ACTION_TYPES.TENANTS.GET_TENANTS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.tenants._(),
      params,
    }),
    {
      withPrevPageOnLessTotal: true,
    }
  ),
  addUsersToTenant: apiReqSagaCreator<
    AddUserToTenantParams,
    GetUserToTenantResult
  >(API_REQ_ACTION_TYPES.TENANTS.ADD_USERS_TO_TENANT, (args) => ({
    method: HTTP_METHODS.POST,
    url: ApiPaths.tenants.byId.users._({
      [ApiUrlParams.tenantId]: args[ApiUrlParams.tenantId],
    }),
    data: args.body,
  })),
  removeUserFromTenant: apiReqSagaCreator<
    RemoveUserFromTenantParams,
    RemoveUserFromTenantResult
  >(API_REQ_ACTION_TYPES.TENANTS.REMOVE_USER_FROM_TENANT, (params) => ({
    method: HTTP_METHODS.DELETE,
    url: ApiPaths.tenants.byId.users.byId._({
      [ApiUrlParams.userId]: params[ApiUrlParams.userId],
      [ApiUrlParams.tenantId]: params[ApiUrlParams.tenantId],
    }),
  })),
  updateUserInTenant: apiReqSagaCreator<
    UpdateUserInTenantParams,
    UpdateUserInTenantResult
  >(API_REQ_ACTION_TYPES.TENANTS.UPDATE_USER_IN_TENANT, (args) => ({
    method: HTTP_METHODS.PUT,
    url: ApiPaths.tenants.byId.users.byId._({
      [ApiUrlParams.userId]: args[ApiUrlParams.userId],
      [ApiUrlParams.tenantId]: args[ApiUrlParams.tenantId],
    }),
    data: args.body,
  })),
  addTenant: apiReqSagaCreator<AddTenantParams, AddTenantResult>(
    API_REQ_ACTION_TYPES.TENANTS.ADD_TENANT,
    (data) => ({
      method: HTTP_METHODS.POST,
      url: ApiPaths.tenants._(),
      data,
    })
  ),
  updateTenant: apiReqSagaCreator<UpdateTenantParams, UpdateTenantResult>(
    API_REQ_ACTION_TYPES.TENANTS.UPDATE_TENANT,
    (args) => ({
      method: HTTP_METHODS.PUT,
      url: ApiPaths.tenants.byId._({
        [ApiUrlParams.tenantId]: args[ApiUrlParams.tenantId],
      }),
      data: excludeObjectProperty(args, ApiUrlParams.tenantId),
    })
  ),
  deleteTenant: apiReqSagaCreator<DeleteTenantParams, DeleteTenantResult>(
    API_REQ_ACTION_TYPES.TENANTS.DELETE_TENANT,
    (params) => ({
      method: HTTP_METHODS.DELETE,
      url: ApiPaths.tenants.byId._({
        [ApiUrlParams.tenantId]: params[ApiUrlParams.tenantId],
      }),
    })
  ),
  getUserTenants: apiReqSagaCreator<GetUserTenantsParams, GetUserTenantsResult>(
    API_REQ_ACTION_TYPES.USERS.GET_USER_TENANTS,
    (params) => ({
      method: HTTP_METHODS.GET,
      url: ApiPaths.users.byId.tenants._({
        [ApiUrlParams.userId]: params[ApiUrlParams.userId],
      }),
    })
  ),
};

export default Tenants;
