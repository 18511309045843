import { UserAgreements } from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAgreementStatus } from './useAgreementStatus';

export default function AgreementsPageRouterConfig() {
  const { agreementStatus } = useAgreementStatus();

  const needsToAgree = agreementStatus.length > 0;

  return [
    needsToAgree ? null : (
      <Redirect
        from={ROUTES.user.agreements._()}
        to="/"
        key="AgreementsRouteModuleRedirectFrom"
      />
    ),
    <Route
      path={ROUTES.user.agreements._()}
      component={onlySignedInPage(UserAgreements)}
      exact
      key="AgreementsRouteModulePageRoute"
    />,
    needsToAgree ? (
      <Redirect
        to={ROUTES.user.agreements._()}
        key="AgreementsRouteModuleRedirectTo"
      />
    ) : null,
  ];
}
