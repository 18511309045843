import { PostOIDC } from 'app/routes/lazyPages';
import React from 'react';
import { Route } from 'react-router-dom';

import OIDCLinkRouterConfig from './linking/routeConfig';

export default function OIDCRouterConfig() {
  return [
    <Route path="/post-oidc" component={PostOIDC} exact key="PostOIDCRoute" />,
    ...OIDCLinkRouterConfig(),
  ];
}
