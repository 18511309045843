import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Spinner from 'components/atoms/spinner';

import { TenantRole } from 'api/endpoints/tenants';
import { UserRole } from 'api/endpoints/users';
import ApiSelectors from 'api/selectors';

import UsersSelectors from 'store/users/selectors';

import { USER_ROLE } from 'constants/roles';

const accessibleByRole =
  (redirectPath: string) =>
  (roles: Array<UserRole | TenantRole>) =>
  (Component: React.ComponentType) =>
  () => {
    const currentRole = useSelector(UsersSelectors.getCurrentTenantRole);

    const isSignedIn: boolean = useSelector(ApiSelectors.isSignedIn);

    const isLoading: boolean = isSignedIn && !currentRole;

    const isAllow: boolean =
      !!currentRole &&
      (roles.includes(currentRole) || currentRole === USER_ROLE.ADMIN);

    if (isLoading) {
      return <Spinner />;
    }

    if (isAllow) {
      return <Component />;
    }

    return <Redirect to={redirectPath} />;
  };

export default accessibleByRole;
