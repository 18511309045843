import { useAppServices } from 'container/depContext';
import { Patch } from 'container/patch';
import { UserRepoActionKeys } from 'container/services/users/IUserRepo';
import { useCallback, useMemo } from 'react';

import { useAuthCache } from 'api/cache';
import {
  useReadAPIWithServices,
  ReadingCacheType,
  useWriteAPIWithServices,
} from 'api/useAPIWithServices';

import { AGREEMENTS } from './config';
import { AgreementStatus } from './typings';

export function useAgreementStatus() {
  const { getUserRepo } = useAppServices();
  const { getCurrentUserInfo, updateUserTermsAcceptance } = getUserRepo();

  const { loggedIn } = useAuthCache();

  const { data: user, mutate: refreshUser } = useReadAPIWithServices(
    [UserRepoActionKeys.GetCurrentUserInfo, loggedIn ? 'loggedIn' : undefined], // only try loading user data if we're logged in
    getCurrentUserInfo,
    { cacheType: ReadingCacheType.ConfigurationMainEntity }
  );

  const { trigger: _updateAgreementStatus } = useWriteAPIWithServices(
    [UserRepoActionKeys.UpdateTermsAcceptance, user?.id],
    (patch: Patch) => updateUserTermsAcceptance(user!, patch)
  );

  const updateAgreementStatus = useCallback(
    async (patch: Patch) => {
      await _updateAgreementStatus(patch);
      await refreshUser();
    },
    [refreshUser, _updateAgreementStatus]
  );

  const agreementStatus = useMemo(
    (): AgreementStatus[] =>
      user
        ? AGREEMENTS.map((agreement): AgreementStatus | undefined => {
            const userAgreementDate = user[agreement.key];

            if (userAgreementDate === null)
              return { ...agreement, newToUser: true };

            if (userAgreementDate < agreement.lastUpdate) {
              return { ...agreement, newToUser: false };
            }

            return undefined;
          }).filter((value): value is AgreementStatus => value !== undefined)
        : [],
    [user]
  );

  return { agreementStatus, updateAgreementStatus };
}
