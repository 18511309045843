import BaseRepo from 'container/BaseRepo';

import {
  ApplicationConfiguration,
  IApplicationConfigurationRepo,
} from './IApplicationConfiguraton';

export class ApplicationConfigurationRepo
  extends BaseRepo
  implements IApplicationConfigurationRepo
{
  getApplicationConfiguration = () =>
    this.helpers.handleGet<ApplicationConfiguration>(
      '/application-configuration',
      {
        responseStopTransformPaths: ['identity_providers.authenticate'],
      }
    );
}
