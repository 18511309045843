import BaseRepo from 'container/BaseRepo';
import { ApiResponseAsync } from 'container/models';

import { ApiPaths } from 'api/constants';
import { TenantGeneral } from 'api/endpoints/tenants';

import { ITenantsRepo } from './ITenants';

export class TenantsRepo extends BaseRepo implements ITenantsRepo {
  getTenants = (): ApiResponseAsync<DataWithTotal<TenantGeneral>> =>
    this.helpers.handleGet(ApiPaths.tenants._());
}
