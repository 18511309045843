import UiUrlParams from 'constants/uiUrlParams';

import pathCreator from 'utils/pathCreator';

import {
  UiWithDefinitionPreviewParams,
  UiWithDraftProcessId,
  UiWithEmailTemplateId,
  UiWithFieldId,
  UiWithFormId,
  UiWithFtpConnectionId,
  UiWithFtpPollingScenarioId,
  UiWithGroupId,
  UiWithId,
  UiWithInboundEmailScenarioId,
  UiWithOidcProvider,
  UiWithOtt,
  UiWithOutboundEmailConnectionId,
  UiWithPrintRenderInstanceId,
  UiWithPrintRenderScenarioId,
  UiWithProcessInstanceId,
  UiWithReleaseOrDraftProcessId,
  UiWithScanInstanceId,
  UiWithScanScenarioId,
  UiWithSource,
  UiWithSplitDocInstanceId,
  UiWithStepId,
  UiWithTmsConnectionId,
  UiWithToken,
} from './uiPathParams';

export type EmailPollingUiParams = UiWithReleaseOrDraftProcessId;

export type EmailPollingUiParamsPreview = UiWithDefinitionPreviewParams;

export type EmailPollingDetailsUiParams = UiWithReleaseOrDraftProcessId &
  UiWithInboundEmailScenarioId;

export type EmailPollingDetailsUiParamsPreview = UiWithDefinitionPreviewParams &
  UiWithInboundEmailScenarioId;

export type DetailsMobileUiParams = UiWithReleaseOrDraftProcessId &
  UiWithProcessInstanceId &
  UiWithDraftProcessId;

export type ExecutionDetailsUiParams = UiWithReleaseOrDraftProcessId;

export type TmsConnectionsDetailsUiParams = UiWithTmsConnectionId;

export type FtpConnectionsDetailsUiParams = UiWithFtpConnectionId;

export type OcrPreviewUiParams = UiWithDefinitionPreviewParams;

export type OcrTestPreviewUiParams = UiWithDefinitionPreviewParams;

export type PrintRenderPreviewUiParam = UiWithDefinitionPreviewParams;

export type PrintRenderDetailsPreviewUiParams = UiWithDefinitionPreviewParams &
  UiWithPrintRenderScenarioId;

export type SystemLogsUiParams = UiWithId & UiWithSource;

export type ExecutionDetailsUiParamsAll = UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsAllSearch = UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsRunning = UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsRunningSearch =
  UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsSuccess = UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsSuccessSearch =
  UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsFailed = UiWithReleaseOrDraftProcessId;

export type ExecutionDetailsUiParamsFailedSearch =
  UiWithReleaseOrDraftProcessId;

export type ExecutionPrintRenderUiParams = UiWithReleaseOrDraftProcessId;

export type ExecutionPrintRenderDetailsUiParams =
  UiWithReleaseOrDraftProcessId & UiWithPrintRenderInstanceId;

export type ExecutionPrintRenderSuccessUiParams = UiWithReleaseOrDraftProcessId;

export type ExecutionPrintRenderFailedUiParams = UiWithReleaseOrDraftProcessId;

export type ExternalFromUiParams = UiWithToken;

export type PrintFileUiParams = UiWithReleaseOrDraftProcessId;

export type EditProcessFormUiParams = UiWithReleaseOrDraftProcessId &
  UiWithFormId;

export type EditorCreateFormUiParams = UiWithReleaseOrDraftProcessId;

export type EditorEditFormUiParams = UiWithReleaseOrDraftProcessId &
  UiWithFormId;

export type EditorDetailsFormUiParams = UiWithReleaseOrDraftProcessId &
  UiWithFormId;

export type EditSecurityGroupUiParams = UiWithReleaseOrDraftProcessId &
  UiWithGroupId;

export type CreateSecurityGroupUiParams = UiWithReleaseOrDraftProcessId;

export type DefinitionSecurityUiParams = UiWithReleaseOrDraftProcessId;

export type ProcessDefinitionDetailsUiParams = UiWithDefinitionPreviewParams;

export type EmailTemplatesPreviewUiParams = UiWithDefinitionPreviewParams;

export type IndexingSourcesPreviewUiParams = UiWithDefinitionPreviewParams;

export type EmailTemplatesDetailsPreviewUiParams =
  UiWithDefinitionPreviewParams & UiWithEmailTemplateId;

export type DefinitionFieldsVersionUiParams = UiWithDefinitionPreviewParams;

export type DefinitionFormDetailsPreviewUiParams =
  UiWithDefinitionPreviewParams & UiWithFormId;

export type DefinitionFormsPreviewUiParams = UiWithDefinitionPreviewParams;

export type ScanPreviewUiParams = UiWithDefinitionPreviewParams;

export type ScanDetailsPreviewUiParams = UiWithDefinitionPreviewParams &
  UiWithScanScenarioId;

export type DefinitionSecurityPreviewUiParams = UiWithDefinitionPreviewParams;

export type SecurityGroupPreviewUiParams = UiWithDefinitionPreviewParams &
  UiWithGroupId;

export type ProcessInstanceUiParams = UiWithReleaseOrDraftProcessId &
  UiWithProcessInstanceId &
  UiWithDraftProcessId;

export type InstanceEditUiParams = UiWithReleaseOrDraftProcessId &
  UiWithProcessInstanceId &
  UiWithDraftProcessId;

export type ScanDocViewUiParams = UiWithReleaseOrDraftProcessId &
  UiWithScanScenarioId &
  UiWithScanInstanceId;

export type ScanScenarioInstanceUiParams = UiWithReleaseOrDraftProcessId &
  UiWithScanScenarioId;

export type RegistrationUiParams = UiWithOtt;

export type ResetPasswordUiParams = UiWithOtt;

export type SplitDocumentsUiParams = UiWithDraftProcessId &
  UiWithReleaseOrDraftProcessId &
  UiWithSplitDocInstanceId &
  UiWithFieldId &
  UiWithFormId &
  UiWithProcessInstanceId;

export type EditProcessPreviewUiParams = UiWithDefinitionPreviewParams;

export type FtpPollingPreviewUiParams = UiWithDefinitionPreviewParams;

export type FtpPollingDetailsPreviewUiParams = UiWithDefinitionPreviewParams &
  UiWithFtpPollingScenarioId;

// todo: separate routes
const ROUTES = {
  process: {
    registration: {
      _: pathCreator<RegistrationUiParams>('/registration/:ott'),
    },
    forgotPassword: {
      _: pathCreator('/forgot-password'),
    },
    resetPassword: {
      _: pathCreator<ResetPasswordUiParams>('/reset-password/:ott'),
    },
    home: {
      _: pathCreator('/home'),
    },
    definition: {
      _: pathCreator('/process-definition'),
      details: {
        _: pathCreator<ProcessDefinitionDetailsUiParams>(
          '/process-definition/:releaseOrDraftProcessId'
        ),
        version: {
          _: pathCreator<UiWithDefinitionPreviewParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId'
          ),
          fields: pathCreator<DefinitionFieldsVersionUiParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/fields'
          ),
          forms: pathCreator<DefinitionFormsPreviewUiParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/forms'
          ),
          security: pathCreator<DefinitionSecurityPreviewUiParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/security'
          ),
          emailTemplates: pathCreator<EmailTemplatesPreviewUiParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/emailTemplates'
          ),
          indexingSources: pathCreator<IndexingSourcesPreviewUiParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/indexingSources'
          ),
          scanning: pathCreator<UiWithDefinitionPreviewParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/scanning'
          ),
          processPreview: pathCreator<UiWithDefinitionPreviewParams>(
            '/process-definition/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId/process-preview'
          ),
        },
        versions: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/process-definition/:releaseOrDraftProcessId/versions'
        ),
        fields: pathCreator<UiWithDraftProcessId>(
          '/process-definition/:draftProcessId/field-aliases'
        ),
        forms: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/process-definition/:releaseOrDraftProcessId/forms'
        ),
        security: pathCreator<DefinitionSecurityUiParams>(
          '/process-definition/:releaseOrDraftProcessId/security'
        ),
        emailTemplates: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/process-definition/:releaseOrDraftProcessId/emailTemplates'
        ),
        indexingSources: pathCreator<IndexingSourcesPreviewUiParams>(
          '/process-definition/:releaseOrDraftProcessId/indexingSources'
        ),
        processPreview: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/process-definition/:releaseOrDraftProcessId/process-preview'
        ),
      },
      services: {
        ocr: {
          _: pathCreator<UiWithReleaseOrDraftProcessId>(
            '/services/:releaseOrDraftProcessId/ocr'
          ),
          preview: {
            _: pathCreator<OcrPreviewUiParams>(
              '/services/:releaseOrDraftProcessId/ocr/version/:processVersionId/from/:draftProcessId'
            ),
          },
          test: {
            _: pathCreator<UiWithReleaseOrDraftProcessId>(
              '/services/:releaseOrDraftProcessId/ocr/test'
            ),
            preview: pathCreator<OcrTestPreviewUiParams>(
              '/services/:releaseOrDraftProcessId/ocr/version/:processVersionId/from/:draftProcessId/test'
            ),
          },
        },
        printRender: {
          _: pathCreator<UiWithReleaseOrDraftProcessId>(
            '/services/:releaseOrDraftProcessId/print-render'
          ),
          preview: {
            _: pathCreator<PrintRenderPreviewUiParam>(
              '/services/:releaseOrDraftProcessId/print-render/version/:processVersionId/from/:draftProcessId'
            ),
          },
        },
        scanning: {
          _: pathCreator<UiWithReleaseOrDraftProcessId>(
            '/services/:releaseOrDraftProcessId/scanning'
          ),
          preview: {
            _: pathCreator<ScanPreviewUiParams>(
              '/services/:releaseOrDraftProcessId/scanning/version/:processVersionId/from/:draftProcessId'
            ),
          },
        },
        emailPolling: {
          _: pathCreator<EmailPollingUiParams>(
            '/services/:releaseOrDraftProcessId/email-polling'
          ),
          preview: {
            _: pathCreator<EmailPollingUiParamsPreview>(
              '/services/:releaseOrDraftProcessId/email-polling/version/:processVersionId/from/:draftProcessId'
            ),
          },
          details: {
            _: pathCreator<EmailPollingDetailsUiParams>(
              '/services/:releaseOrDraftProcessId/email-polling/:inboundEmailScenarioId'
            ),
            preview: pathCreator<EmailPollingDetailsUiParamsPreview>(
              '/services/:releaseOrDraftProcessId/email-polling/version/:processVersionId/from/:draftProcessId/:inboundEmailScenarioId'
            ),
          },
        },
        ftpPolling: {
          _: pathCreator<UiWithReleaseOrDraftProcessId>(
            '/services/:releaseOrDraftProcessId/ftp-polling'
          ),
          preview: {
            _: pathCreator<FtpPollingPreviewUiParams>(
              '/services/:releaseOrDraftProcessId/ftp-polling/version/:processVersionId/from/:draftProcessId'
            ),
          },
          details: {
            _: pathCreator<
              UiWithReleaseOrDraftProcessId & UiWithFtpPollingScenarioId
            >(
              '/services/:releaseOrDraftProcessId/ftp-polling/:ftpPollingScenarioId'
            ),
            preview: pathCreator<FtpPollingDetailsPreviewUiParams>(
              '/services/:releaseOrDraftProcessId/ftp-polling/version/:processVersionId/from/:draftProcessId/:ftpPollingScenarioId'
            ),
          },
        },
      },
    },
    createProcessForm: pathCreator<UiWithReleaseOrDraftProcessId>(
      '/create-process-form/:releaseOrDraftProcessId'
    ),
    editProcessForm: pathCreator<EditProcessFormUiParams>(
      '/edit-process-form/:releaseOrDraftProcessId/:formId'
    ),
    createSecurityGroup: pathCreator<CreateSecurityGroupUiParams>(
      '/process-definition/:releaseOrDraftProcessId/security/group/create'
    ),
    editSecurityGroup: pathCreator<EditSecurityGroupUiParams>(
      '/process-definition/:releaseOrDraftProcessId/security/group/:groupId/edit'
    ),
    securityGroupDetails: {
      _: pathCreator<UiWithReleaseOrDraftProcessId & UiWithGroupId>(
        '/process-definition/:releaseOrDraftProcessId/security/group/:groupId/details'
      ),
    },
    formDetails: {
      _: pathCreator<UiWithReleaseOrDraftProcessId & UiWithFormId>(
        '/form-preview/:releaseOrDraftProcessId/forms/:formId'
      ),
      preview: pathCreator<DefinitionFormDetailsPreviewUiParams>(
        '/form-preview/:releaseOrDraftProcessId/forms/:formId/version/:processVersionId/from/:draftProcessId'
      ),
    },
    emailTemplateDetails: {
      _: pathCreator<UiWithReleaseOrDraftProcessId & UiWithEmailTemplateId>(
        '/email-templates-preview/:releaseOrDraftProcessId/email-templates/:emailTemplateId'
      ),
      preview: pathCreator<EmailTemplatesDetailsPreviewUiParams>(
        '/email-templates-preview/:releaseOrDraftProcessId/email-templates/:emailTemplateId/version/:processVersionId/from/:draftProcessId'
      ),
    },
    printRenderDetails: {
      _: pathCreator<
        UiWithReleaseOrDraftProcessId & UiWithPrintRenderScenarioId
      >(
        '/print-render-preview/:releaseOrDraftProcessId/scenario/:printRenderScenarioId'
      ),
      preview: pathCreator<PrintRenderDetailsPreviewUiParams>(
        '/print-render-preview/:releaseOrDraftProcessId/scenario/:printRenderScenarioId/version/:processVersionId/from/:draftProcessId'
      ),
    },
    scanningDetails: {
      _: pathCreator<UiWithReleaseOrDraftProcessId & UiWithScanScenarioId>(
        '/scanning-preview/:releaseOrDraftProcessId/scanning/:scanConfigurationId'
      ),
      preview: pathCreator<ScanDetailsPreviewUiParams>(
        '/scanning-preview/:releaseOrDraftProcessId/scanning/:scanConfigurationId/version/:processVersionId/from/:draftProcessId'
      ),
    },
    scanningScenario: pathCreator<ScanScenarioInstanceUiParams>(
      '/scanning-scenario/:releaseOrDraftProcessId/:scanConfigurationId'
    ),
    scanningScenarioOld: pathCreator<ScanScenarioInstanceUiParams>(
      '/scanning-scenario-old/:releaseOrDraftProcessId/:scanConfigurationId'
    ),
    editProcess: {
      _: pathCreator<UiWithDefinitionPreviewParams>(
        '/edit-process/:releaseOrDraftProcessId'
      ),
      preview: {
        _: pathCreator<EditProcessPreviewUiParams>(
          '/edit-process/:releaseOrDraftProcessId/version/:processVersionId/from/:draftProcessId'
        ),
      },
      emailTemplates: {
        _: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/edit-process/:releaseOrDraftProcessId/email-templates'
        ),
        details: {
          _: pathCreator<UiWithReleaseOrDraftProcessId & UiWithEmailTemplateId>(
            '/edit-process/:releaseOrDraftProcessId/email-templates/details/:emailTemplateId'
          ),
          preview: pathCreator(''),
        },
      },
      forms: {
        _: pathCreator<UiWithReleaseOrDraftProcessId>(
          '/edit-process/:releaseOrDraftProcessId/forms'
        ),
        create: pathCreator<EditorCreateFormUiParams>(
          '/edit-process/:releaseOrDraftProcessId/forms/create'
        ),
        edit: pathCreator<EditorEditFormUiParams>(
          '/edit-process/:releaseOrDraftProcessId/forms/edit/:formId'
        ),
        details: {
          _: pathCreator<EditorDetailsFormUiParams>(
            '/edit-process/:releaseOrDraftProcessId/forms/details/:formId'
          ),
          preview: pathCreator(''),
        },
      },
    },
    scanningDocumentView: pathCreator<ScanDocViewUiParams>(
      '/scan/:releaseOrDraftProcessId/document-view/:scanConfigurationId/:scanInstanceId'
    ),
    printFile: pathCreator<PrintFileUiParams>(
      '/print-file/:releaseOrDraftProcessId/print-render/details'
    ),
    execution: {
      _: pathCreator('/process-execution'),
      details: {
        _: pathCreator<ExecutionDetailsUiParams>(
          '/process-execution/:releaseOrDraftProcessId'
        ),
        all: {
          _: pathCreator<ExecutionDetailsUiParamsAll>(
            '/process-execution/:releaseOrDraftProcessId/all'
          ),
          search: pathCreator<ExecutionDetailsUiParamsAllSearch>(
            '/process-execution/:releaseOrDraftProcessId/all/search'
          ),
        },
        running: {
          _: pathCreator<ExecutionDetailsUiParamsRunning>(
            '/process-execution/:releaseOrDraftProcessId/running'
          ),
          search: pathCreator<ExecutionDetailsUiParamsRunningSearch>(
            '/process-execution/:releaseOrDraftProcessId/running/search'
          ),
        },
        success: {
          _: pathCreator<ExecutionDetailsUiParamsSuccess>(
            '/process-execution/:releaseOrDraftProcessId/success'
          ),
          search: pathCreator<ExecutionDetailsUiParamsSuccessSearch>(
            '/process-execution/:releaseOrDraftProcessId/success/search'
          ),
        },
        failed: {
          _: pathCreator<ExecutionDetailsUiParamsFailed>(
            '/process-execution/:releaseOrDraftProcessId/failed'
          ),
          search: pathCreator<ExecutionDetailsUiParamsFailedSearch>(
            '/process-execution/:releaseOrDraftProcessId/failed/search'
          ),
        },
        printRender: {
          _: pathCreator<ExecutionPrintRenderUiParams>(
            '/process-execution/:releaseOrDraftProcessId/print-render'
          ),
          details: {
            _: pathCreator<ExecutionPrintRenderDetailsUiParams>(
              '/process-execution/:releaseOrDraftProcessId/print-render/details/:printRenderInstanceId'
            ),
          },
          success: {
            _: pathCreator<ExecutionPrintRenderSuccessUiParams>(
              '/process-execution/:releaseOrDraftProcessId/print-render/success'
            ),
          },
          failed: {
            _: pathCreator<ExecutionPrintRenderFailedUiParams>(
              '/process-execution/:releaseOrDraftProcessId/print-render/failed'
            ),
          },
          overrides: {
            _: pathCreator<UiWithReleaseOrDraftProcessId>(
              '/process-execution/:releaseOrDraftProcessId/print-render/overrides'
            ),
          },
        },
        status: {
          _: pathCreator('/process-execution/:releaseOrDraftProcessId/status'),
        },
      },
    },
    instance: {
      _: pathCreator<ProcessInstanceUiParams>(
        '/process/:releaseOrDraftProcessId/instance/:processInstanceId/:draftProcessId'
      ),
      step: pathCreator<
        UiWithReleaseOrDraftProcessId & UiWithProcessInstanceId & UiWithStepId
      >(
        '/process/:releaseOrDraftProcessId/instance/:processInstanceId/:stepId'
      ),
      detailsMobile: {
        _: pathCreator<DetailsMobileUiParams>(
          '/process/:releaseOrDraftProcessId/instance/:processInstanceId/:draftProcessId/details-mobile'
        ),
      },
      edit: {
        _: pathCreator<InstanceEditUiParams>(
          '/process/:releaseOrDraftProcessId/instance/:processInstanceId/:draftProcessId/edit'
        ),
      },
      splitDocuments: pathCreator<SplitDocumentsUiParams>(
        '/process/:releaseOrDraftProcessId/instance/:processInstanceId/:draftProcessId/split-documents/:splitDocInstanceId/field-id/:fieldId/form-to-get/:formId'
      ),
    },
    systemLogs: {
      _: pathCreator<SystemLogsUiParams>('/process/:id/system-logs/:source'),
    },
    instanceLogs: {
      _: pathCreator<UiWithId>('/process/:id/instance-logs'),
    },
  },
  management: {
    _: pathCreator('/management'),
    users: {
      _: pathCreator('/management/users'),
    },
    invitations: {
      _: pathCreator('/management/invitations'),
    },
    userAccessLogs: {
      _: pathCreator('/management/user-access-logs'),
    },
    tenants: {
      _: pathCreator('/management/tenants'),
    },
    emailSettings: {
      _: pathCreator('/management/email-settings'),
    },
    connections: {
      _: pathCreator('/management/connections'),
      tms: {
        _: pathCreator('/management/connections/tms'),
        details: {
          _: pathCreator<TmsConnectionsDetailsUiParams>(
            '/management/connections/tms/:tmsConnectionId'
          ),
        },
      },
      ftp: {
        _: pathCreator('/management/connections/ftp'),
        details: {
          _: pathCreator<FtpConnectionsDetailsUiParams>(
            '/management/connections/ftp/:ftpConnectionId'
          ),
        },
      },
      email: {
        _: pathCreator('/management/connections/email'),
        details: {
          _: pathCreator<UiWithOutboundEmailConnectionId>(
            '/management/connections/email/:outboundEmailConnectionId'
          ),
        },
      },
      oidc: {
        _: pathCreator('/management/connections/oidc'),
      },
    },
  },
  external: {
    _: pathCreator('/external'),
    form: {
      _: pathCreator<ExternalFromUiParams>('/external/form/:token'),
      submitted: pathCreator('/external/form/submitted'),
    },
  },
  user: {
    agreements: {
      _: pathCreator('/user/agreements'),
    },
    oidc: {
      preLink: {
        _: pathCreator('/user/oidc/prelink'),
      },
      postLink: {
        _: pathCreator<UiWithOidcProvider>(
          `/user/oidc/postlink/:${UiUrlParams.oidcProvider}`
        ),
      },
    },
  },
};

export default ROUTES;
