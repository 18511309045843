export function FreshdeskLink({
  articleID,
  linkText,
}: {
  articleID: number;
  linkText: string;
}) {
  return (
    <a
      href={`https://workoptima.freshdesk.com/support/solutions/articles/${articleID}`}
      onClick={(e) => {
        e.preventDefault();
        FreshworksWidget('open', 'article', {
          id: articleID,
        });
      }}
    >
      {linkText}
    </a>
  );
}
