import { Agreement } from './typings';

export const AGREEMENTS: Array<Agreement> = [
  {
    humanAgreementName: 'Terms of Service',
    key: 'acceptedTermsAt',
    lastUpdate: new Date('2020-01-01'),
    articleID: 47000989037,
  },
  {
    humanAgreementName: 'Privacy Policy',
    key: 'acceptedPrivacyPolicyAt',
    lastUpdate: new Date('2020-01-01'),
    articleID: 47000988860,
  },
];
