import BaseRepo from 'container/BaseRepo';
import { buildBadRequestApiError } from 'container/helpers/errors';
import { ApiResponseAsync } from 'container/models';

import { ProcessGeneral } from 'api/endpoints/processes';

import { E2OpenConfig, E2OpenTestResponse, IE2OpenRepo } from './IE2Open';

export class E2OpenRepo extends BaseRepo implements IE2OpenRepo {
  test = (
    processDraft: ProcessGeneral,
    payload: E2OpenConfig
  ): ApiResponseAsync<E2OpenTestResponse> => {
    const link = processDraft.links?.verifyE2OpenCredentials.href;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "verify E2Open credentials" is not available')
        )
      );
    }

    return this.helpers.handlePost(link, payload);
  };
}
