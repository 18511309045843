import { definitionRoutes } from 'app/routes/definitionRoutes';
import { devRoutes } from 'app/routes/devRoutes';
import { executionRoutes } from 'app/routes/executionRoutes';
import { managementRoutes } from 'app/routes/managementRoutes';
import { processRoutes } from 'app/routes/processRoutes';

const routes: RoutesConfig = [
  ...processRoutes,
  ...definitionRoutes,
  ...executionRoutes,
  ...managementRoutes,
  ...devRoutes,
];

export { routes };
