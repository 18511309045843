import { PostOIDCLink, PreOIDCLink } from 'app/routes/lazyPages';
import ROUTES from 'app/routes/paths';
import onlySignedInPage from 'hocs/onlySignedInPage';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAccountLinkingConfig } from './useAccountLinkingConfig';

export default function OIDCLinkRouterConfig() {
  const { info } = useAccountLinkingConfig();

  return [
    info?.canBeLinked === false ? (
      <Redirect
        from={ROUTES.user.oidc.preLink._()}
        to="/"
        exact
        key="OIDCLinkPreNotApplicableRedirect"
      />
    ) : null,
    <Route
      path={ROUTES.user.oidc.preLink._()}
      component={onlySignedInPage(PreOIDCLink)}
      exact
      key="OIDCLinkPre"
    />,
    <Route
      path={ROUTES.user.oidc.postLink._()}
      component={onlySignedInPage(PostOIDCLink)}
      exact
      key="OIDCLinkPost"
    />,
    info?.canBeLinked ? (
      <Redirect to={ROUTES.user.oidc.preLink._()} key="OIDCLinkPreRedirectTo" />
    ) : null,
  ];
}
