import BaseRepo from 'container/BaseRepo';
import { buildBadRequestApiError } from 'container/helpers/errors';
import { ApiResponseAsync, isApiError } from 'container/models';
import { Patch } from 'container/patch';

import { SystemUserGeneral, User } from 'api/endpoints/users';

import { IdPConfig, IManagementOIDCRepo } from './IManagementOIDC';

export class ManagementOIDCRepo
  extends BaseRepo
  implements IManagementOIDCRepo
{
  getIdentityProviderConfig = async (
    user: User
  ): ApiResponseAsync<IdPConfig | undefined> => {
    const link = user.links?.['getOrganizationIdentityProvider'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error(
            'link "get organization identity provider" is not available'
          )
        )
      );
    }

    const result = await this.helpers.handleGet<IdPConfig | string>(link.href);
    if (isApiError(result)) return result;

    if (typeof result === 'string') return undefined;

    return result;
  };

  createIdentityProviderConfig = (
    user: User,
    config: IdPConfig
  ): ApiResponseAsync<IdPConfig> => {
    const link = user.links?.['createOrganizationIdentityProvider'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error(
            'link "create organization identity provider" is not available'
          )
        )
      );
    }

    return this.helpers.handlePost(link.href, config);
  };

  updateIdentityProviderConfig = (
    config: IdPConfig,
    patch: Patch
  ): ApiResponseAsync<IdPConfig> => {
    const link = config.links?.['update'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error(
            'link "update organization identity provider" is not available'
          )
        )
      );
    }

    return this.helpers.executePatch(link, patch);
  };

  deleteIdentityProviderConfig = (
    config: IdPConfig
  ): ApiResponseAsync<void> => {
    const link = config.links?.['delete'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error(
            'link "delete organization identity provider" is not available'
          )
        )
      );
    }

    return this.helpers.handleDelete(link.href);
  };

  excludeUser = (user: SystemUserGeneral): ApiResponseAsync<void> => {
    const link = user?.links?.['setExcludeIdentityProvider'];
    if (!link)
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "set exclude identity provider" is not available')
        )
      );

    return this.helpers.handlePost(link.href, undefined);
  };

  includeUser = (user: SystemUserGeneral): ApiResponseAsync<void> => {
    const link = user?.links?.['removeExcludeIdentityProvider'];
    if (!link)
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "remove exclude identity provider" is not available')
        )
      );

    return this.helpers.handlePost(link.href, undefined);
  };
}
