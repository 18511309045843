import BaseRepo from 'container/BaseRepo';
import { buildBadRequestApiError } from 'container/helpers/errors';
import {
  ApiResponseAsync,
  isApiError,
  translateAxiosErrorToApiError,
} from 'container/models';
import { Patch } from 'container/patch';

import { ApiPaths } from 'api/constants';
import { User } from 'api/endpoints/users';

import { AccountLinkingInfo, IUserRepo } from './IUserRepo';

export class UserRepo extends BaseRepo implements IUserRepo {
  resetPassword = (username: string) =>
    this.client
      .post<void>(`/auth/forgot-password`, { username })
      .then((res) => res.data)
      .catch(translateAxiosErrorToApiError);

  getCurrentUserInfo = async (): ApiResponseAsync<User> => {
    const user = await this.helpers.handleGet<User>(ApiPaths.users.info._());

    if (isApiError(user)) return user;

    user.acceptedPrivacyPolicyAt =
      user.acceptedPrivacyPolicyAt && new Date(user.acceptedPrivacyPolicyAt);

    user.acceptedTermsAt =
      user.acceptedTermsAt && new Date(user.acceptedTermsAt);

    return user;
  };

  updateUserTermsAcceptance = (
    user: User,
    patch: Patch
  ): ApiResponseAsync<void> => {
    const link = user.links?.['updateUserPolicies'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "update user policies" is not available')
        )
      );
    }

    return this.helpers.executePatch(link, patch);
  };

  getUserAccountLinkingState = (
    user: User
  ): ApiResponseAsync<AccountLinkingInfo> => {
    const link = user.links?.['userCanBeLinkedToIdentityProvider'];
    if (!link)
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "userCanBeLinkedToIdentityProvider" is not available')
        )
      );

    return this.helpers.handleGet(link.href);
  };
}
