import cn from 'classnames';
import React, { memo, ReactNode, useCallback, useMemo } from 'react';

import Text from 'components/atoms/text';
import Tooltip, { Props as TooltipProps } from 'components/molecules/tooltip';

import { HIDE_FROM_FOCUS } from 'constants/global';

import sm from './styles.module.scss';

type ButtonView = 'Default' | 'Add_More' | 'Only_Icon';

interface Props {
  view: ButtonView;
  label?: string;
  onClick?: ReactCallBack;
  isDisabled?: boolean;
  isOutlined?: boolean;
  isBig?: boolean;
  isSmall?: boolean;
  isSmallLabel?: boolean;
  isDanger?: boolean;
  isWide?: boolean;
  type?: ButtonType;
  preIcon?: ReactNode;
  postIcon?: ReactNode;
  withFullWidth?: boolean;
  withBlueLabel?: boolean;
  withPrimaryStylesOnHover?: boolean;
  withGreyBorder?: boolean;
  withSamePadding?: boolean;
  isActive?: boolean;
  withAutoFocus?: boolean;
  tooltipText?: string;
  classes?: string;
  backgroundColor?: string;
  borderColor?: string;
}

export const Button = ({
  view,
  withAutoFocus,
  label,
  type,
  onClick,
  isOutlined,
  isDanger,
  isBig,
  isSmall,
  isSmallLabel,
  isDisabled,
  isActive,
  isWide,
  preIcon,
  postIcon,
  withFullWidth,
  withBlueLabel,
  withPrimaryStylesOnHover,
  withGreyBorder,
  tooltipText,
  classes,
  withSamePadding,
  backgroundColor,
  borderColor,
}: Props) => {
  const RenderComponent:
    | React.FunctionComponent<TooltipProps>
    | React.ExoticComponent<{ children: React.ReactNode }> = useMemo(
    () => (tooltipText ? Tooltip : React.Fragment),
    [tooltipText]
  );

  const renderIcon: (classNames: string, icon: ReactNode) => JSX.Element =
    useCallback(
      (classNames: string, icon) => <div className={classNames}>{icon}</div>,
      []
    );

  return (
    <RenderComponent {...(tooltipText && { title: tooltipText })}>
      <button
        {...(isDisabled && { tabIndex: HIDE_FROM_FOCUS })}
        type={onClick && !type ? 'button' : type}
        disabled={isDisabled}
        autoFocus={withAutoFocus}
        className={useMemo(
          () =>
            cn([
              sm[view],
              {
                [sm.Outlined]: isOutlined,
                [sm.WithGreyBorder]: isOutlined && withGreyBorder,
                [sm.Danger]: isDanger && !isDisabled,
                [sm[`${view}_Disabled`]]: isDisabled,
                [sm[`${view}_isBig`]]: isBig,
                [sm[`${view}_WithSamePadding`]]: withSamePadding,
                [sm.IsSmall]: isSmall,
                [sm.IsWide]: isWide,
                [sm.OutlinedDisabled]: isDisabled && isOutlined,
                [sm.Icon]: preIcon || postIcon,
                [sm.FullWidth]: withFullWidth,
                [sm.WithPrimaryHover]: withPrimaryStylesOnHover,
                [sm.PrimaryPressed]: isActive,
              },
              classes,
            ]),
          [
            view,
            isOutlined,
            withGreyBorder,
            isDanger,
            isDisabled,
            isBig,
            isSmall,
            isWide,
            preIcon,
            postIcon,
            withFullWidth,
            withPrimaryStylesOnHover,
            isActive,
            withSamePadding,
            classes,
          ]
        )}
        style={{
          backgroundColor,
          borderColor,
        }}
        onClick={onClick}
      >
        {!!preIcon &&
          renderIcon(
            cn(sm.Icon_Wrapper, sm.IconLeft, {
              [sm.IconLeft_WithoutPadding]: !label,
            }),
            preIcon
          )}
        {!!label && (
          <Text
            content={label}
            withGreyColor={isDisabled && isOutlined}
            withWhiteColor={!isOutlined}
            with12Font={isSmallLabel}
            with17Font={isBig && !isSmallLabel}
            withSemiBoldWeight={!isDisabled && !isSmallLabel}
            withBlueColor={withBlueLabel}
          />
        )}
        {!!postIcon &&
          renderIcon(
            cn(sm.Icon_Wrapper, sm.IconRight, {
              [sm.IconRight_WithoutPadding]: !label,
            }),
            postIcon
          )}
      </button>
    </RenderComponent>
  );
};

export default memo(Button);
