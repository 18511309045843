import { ApiResponseAsync } from 'container/models';
import { Patch } from 'container/patch';

import { User } from 'api/endpoints/users';

import { IdentityProviderName } from '../managementOIDC/IManagementOIDC';

export enum UserRepoActionKeys {
  ResetPassword = 'UserResetPassword',
  GetCurrentUserInfo = 'UserGetCurrentUserInfo',
  UpdateTermsAcceptance = 'UserUpdateTermsAcceptance',
  GetAccountLinkingState = 'UserGetAccountLinkingState',
}

export interface AccountLinkingInfo {
  canBeLinked: boolean;
  provider?: IdentityProviderName;
  postAuthenticateUrl?: string;
}

export interface IUserRepo {
  resetPassword(username: string): ApiResponseAsync<void>;

  getCurrentUserInfo(): ApiResponseAsync<User>;

  updateUserTermsAcceptance(user: User, patch: Patch): ApiResponseAsync<void>;

  getUserAccountLinkingState(user: User): ApiResponseAsync<AccountLinkingInfo>;
}
