import { TenantRole } from 'api/endpoints/tenants';
import { UserRole } from 'api/endpoints/users';

const USER_ROLE = {
  ADMIN: 'admin',
  USER: 'user',
} as const;

const TENANT_ROLE = {
  ADMIN: 'tenant_admin',
  MANAGER: 'tenant_manager',
  USER: 'tenant_user',
} as const;

const SYSTEM_ROLE_SHORT = {
  [USER_ROLE.ADMIN]: 'OA',
};

const SYSTEM_ROLE_FULL: Record<UserRole, string> = {
  [USER_ROLE.ADMIN]: 'Organization Admin',
  [USER_ROLE.USER]: 'User',
};

const TENANT_ROLE_SHORT: Record<TenantRole, string> = {
  [TENANT_ROLE.ADMIN]: 'A',
  [TENANT_ROLE.MANAGER]: 'M',
  [TENANT_ROLE.USER]: 'U',
};

const TENANT_ROLE_FULL: Record<TenantRole, string> = {
  [TENANT_ROLE.ADMIN]: 'Tenant admin',
  [TENANT_ROLE.MANAGER]: 'Tenant manager',
  [TENANT_ROLE.USER]: 'Tenant user',
};

const ACCESSIBLE_ROLES = {
  ALL: [TENANT_ROLE.USER, TENANT_ROLE.MANAGER, TENANT_ROLE.ADMIN],
  MANAGERS: [TENANT_ROLE.MANAGER, TENANT_ROLE.ADMIN],
  ADMIN: [USER_ROLE.ADMIN],
};

const ROLE_OPTIONS = [
  {
    label: 'Tenant Admin',
    value: TENANT_ROLE.ADMIN,
  },
  {
    label: 'Tenant Manager',
    value: TENANT_ROLE.MANAGER,
  },
  {
    label: 'Tenant User',
    value: TENANT_ROLE.USER,
  },
];

const getRoleOptionsByRole = (role: string) => {
  switch (role) {
    case TENANT_ROLE.MANAGER:
      return [...ROLE_OPTIONS].reverse().slice(0, 1);
    case TENANT_ROLE.ADMIN:
      return [...ROLE_OPTIONS].reverse().slice(0, 2);
    default:
      return ROLE_OPTIONS;
  }
};

export {
  ROLE_OPTIONS,
  TENANT_ROLE,
  TENANT_ROLE_FULL,
  TENANT_ROLE_SHORT,
  USER_ROLE,
  SYSTEM_ROLE_FULL,
  SYSTEM_ROLE_SHORT,
  getRoleOptionsByRole,
  ACCESSIBLE_ROLES,
};
