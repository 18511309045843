import DMADate from 'assets/icon/dma-date.svg';
import DMAFile from 'assets/icon/dma-file.svg';
import DMANumber from 'assets/icon/dma-number.svg';
import DMASelect from 'assets/icon/dma-selectone.svg';
import DMAText from 'assets/icon/dma-text.svg';
import WorkFlowEmpty from 'assets/img/workflow-empty.svg';

import { FieldGeneral } from 'api/endpoints/processFields';

import { DataModelFieldSubType, DataModelFieldType } from 'constants/global';

const ICONS_MAP = {
  text: {
    src: DMAText,
    width: 40,
  },
  file: {
    src: DMAFile,
    width: 40,
  },
  number: {
    src: DMANumber,
    width: 64,
  },
  select: {
    src: DMASelect,
    width: 82,
  },
  date: {
    src: DMADate,
    width: 40,
  },
  guid: {
    src: DMANumber,
    width: 64,
  },
};

const CLASS_NAMES = [
  'Label',
  '5b80a5',
  '5ba55b',
  '5b67a4',
  '5ba58c',
  '5ba5a5',
  'a55b80',
  'a55b80',
  'a55b80',
  'a5745b',
  '745ba5',
  'a55ba5',
];

const BLOCK_PREFIX = 'CUSTOM';

const BLOCK_TYPE = {
  SET_VARIABLE_VALUE_TO: `${BLOCK_PREFIX}_set_variable_value_to`,
  CHANGE_VARIABLE_VALUE_TO: `${BLOCK_PREFIX}_change_variable_value_to`,
  SEND_SMS_TO: `${BLOCK_PREFIX}_send_sms_to`,
  GET_FORM: `${BLOCK_PREFIX}_get_form`,
  INDEXING: `${BLOCK_PREFIX}_indexing`,
  STEP_DELIMITER: `${BLOCK_PREFIX}_step_delimiter`,
  STEP_DELIMITER_SHADOW: `${BLOCK_PREFIX}_step_delimiter_shadow`,
  DATA_MODEL_ATTRIBUTE: `${BLOCK_PREFIX}_data_model_attribute`,
  DATA_MODEL_FORM: `${BLOCK_PREFIX}_data_model_form`,
  FUNCTION: `${BLOCK_PREFIX}_function`,
  APPEND_TEXT: `${BLOCK_PREFIX}_append_text`,
  SHOW_DOCUMENT: `${BLOCK_PREFIX}_show_document`,
  SHOW_FORM: `${BLOCK_PREFIX}_show_form`,
  PLANE_SEND_EMAIL_TO: `${BLOCK_PREFIX}_plane_send_email_to`,
  PLANE_SEND_SMS_TO: `${BLOCK_PREFIX}_plane_send_sms_to`,
  FINISH_INSTANCE_WITH: `${BLOCK_PREFIX}_finish_instance_with`,
  SEND_FORM_WITH_SMS: `${BLOCK_PREFIX}_send_form_with_sms`,
  CHECK_IN_TRUCK_MATE: `${BLOCK_PREFIX}_check_in_truck_mate`,
  SEND_FILE_TO_OCR: `${BLOCK_PREFIX}_send_file_to_ocr`,
  SUB_STEP: `${BLOCK_PREFIX}_sub_step`,
  SUB_STRING: `${BLOCK_PREFIX}_sub_string`,
  EMAIL_TEMPLATE: `${BLOCK_PREFIX}_email_template`,
  CONSTANT_VARIABLE: `${BLOCK_PREFIX}_constant_variable`,
  SEND_FORM_WITH_EMAIL_TEMPLATE: `${BLOCK_PREFIX}_send_form_with_email_template`,
  SEARCH_INSTANCES: `${BLOCK_PREFIX}_search_instances`,
  UPDATE_INSTANCES: `${BLOCK_PREFIX}_update_instances`,
  SEND_FILE_TO_FTP: `${BLOCK_PREFIX}_send_file_to_ftp`,
  READ_BARCODE: `${BLOCK_PREFIX}_read_barcode`,
  LISTS_CREATE_WITH: 'lists_create_with',
  EXECUTE_REGEX: `${BLOCK_PREFIX}_execute_regex`,
  FIELD_WITH_ALIAS: `${BLOCK_PREFIX}_field_with_alias`,
  MOVE_DOC_TO_INDEX_QUEUE: `${BLOCK_PREFIX}_move_doc_to_index_queue`,
  ADD_LOG_MESSAGE: `${BLOCK_PREFIX}_add_log_message`,
  E2OPEN: `${BLOCK_PREFIX}_e2open`,
};

const REMOVED_STATIC_BLOCKS = {
  [`${BLOCK_PREFIX}_send_email_to`]: 'Send email action',
  [`${BLOCK_PREFIX}_send_form_with_email`]: 'Send email with form action',
  [`${BLOCK_PREFIX}_send_email_with_template`]:
    'Send email with template action',
};

enum ExtensionKey {
  CheckInTruckMate = 'CHECK_IN_TRUCK_MATE',
  Sms = 'SMS',
  SearchInstances = 'SEARCH_INSTANCES',
  UpdateInstances = 'UPDATE_INSTANCES',
  Index = 'INDEX',
  SetTo = 'SET_TO',
  OnlyStatic = 'ONLY_STATIC',
  SendSmsWithForm = 'SEND_SMS_WITH_FORM',
  SendEmailWithTemplateAndForm = 'SEND_EMAIL_WITH_TEMPLATE_AND_FORM',
  FinishProcess = 'FINISH_PROCESS',
  SendFileToFtp = 'SEND_FILE_TO_FTP',
  PlaneSendEmail = 'PLANE_SEND_EMAIL',
}

const getExtensionParts = (type: ExtensionKey) => ({
  name: `${type}_extension`,
  icon: `${type}_icon`,
});

const EXTENSION = {
  [ExtensionKey.CheckInTruckMate]: getExtensionParts(
    ExtensionKey.CheckInTruckMate
  ),
  [ExtensionKey.Sms]: getExtensionParts(ExtensionKey.Sms),
  [ExtensionKey.SearchInstances]: getExtensionParts(
    ExtensionKey.SearchInstances
  ),
  [ExtensionKey.UpdateInstances]: getExtensionParts(
    ExtensionKey.UpdateInstances
  ),
  [ExtensionKey.Index]: getExtensionParts(ExtensionKey.Index),
  [ExtensionKey.SetTo]: getExtensionParts(ExtensionKey.SetTo),
  [ExtensionKey.OnlyStatic]: getExtensionParts(ExtensionKey.OnlyStatic),
  [ExtensionKey.SendSmsWithForm]: getExtensionParts(
    ExtensionKey.SendSmsWithForm
  ),
  [ExtensionKey.SendEmailWithTemplateAndForm]: getExtensionParts(
    ExtensionKey.SendEmailWithTemplateAndForm
  ),
  [ExtensionKey.FinishProcess]: getExtensionParts(ExtensionKey.FinishProcess),
  [ExtensionKey.SendFileToFtp]: getExtensionParts(ExtensionKey.SendFileToFtp),
  [ExtensionKey.PlaneSendEmail]: getExtensionParts(ExtensionKey.PlaneSendEmail),
};

const TEXTS = {
  EMPTY: {
    TITLE: 'Process is not configured',
    DESCRIPTION: 'Please add stage to begin',
    IMG_ALT: 'Empty workspace',
  },
  TOOLTIP: {
    NUMBER: 'Always set the phone number in format 5555551234!',
    DATE: 'Always set date in format mmddyyyy!',
    EMAIL: 'Email format = ***@***.***',
    URL: 'URL format = https://link.***',
  },
};

const getFieldName = (
  field: FieldGeneral,
  parent?: FieldGeneral,
  withIcon = false
) =>
  parent
    ? `${parent.name} > ${field.name}${withIcon ? '%1' : ''}`
    : `${field.name}${withIcon ? '%1' : ''}`;

const EMPTY_STATE_IMG_CONFIG = {
  imgSource: WorkFlowEmpty,
  imgAlt: TEXTS.EMPTY.IMG_ALT,
};

const getDataModelFieldTooltip = (
  field: FieldGeneral,
  parent?: FieldGeneral
) => {
  switch (field.type) {
    case DataModelFieldType.Date:
      return TEXTS.TOOLTIP.DATE;
    case DataModelFieldType.Number:
      switch (field.metadata?.maskType) {
        case DataModelFieldSubType.NumberPhone:
          return TEXTS.TOOLTIP.NUMBER;
        default:
          return getFieldName(field, parent);
      }
    case DataModelFieldType.Text:
      switch (field.metadata?.maskType) {
        case DataModelFieldSubType.TextPhone:
          return TEXTS.TOOLTIP.NUMBER;
        case DataModelFieldSubType.TextEmail:
          return TEXTS.TOOLTIP.EMAIL;
        case DataModelFieldSubType.TextUrl:
          return TEXTS.TOOLTIP.URL;
        default:
          return getFieldName(field, parent);
      }
    default:
      return getFieldName(field, parent);
  }
};

enum BlocklyOutputType {
  String = 'String',
  Number = 'Number',
  Array = 'Array',
}

enum CustomOutputType {
  Form = 'Form',
  EmailTemplate = 'EmailTemplate',
  DMFString = 'DMFString',
  DMFNumber = 'DMFNumber',
  DMFDate = 'DMFDate',
}

const getOutputByModelType = (field: FieldGeneral) => {
  const type = field.metadata?.maskType || field.type;

  switch (type) {
    case DataModelFieldSubType.TextEmail:
    case DataModelFieldSubType.TextUrl:
    case DataModelFieldSubType.TextPhone:
    case DataModelFieldSubType.TextRichText:
    case DataModelFieldType.Text:
      return [BlocklyOutputType.String, CustomOutputType.DMFString];

    case DataModelFieldType.File:
      return [BlocklyOutputType.String, type];

    case DataModelFieldType.Select:
      return [BlocklyOutputType.String, type];

    case DataModelFieldType.Date:
    case DataModelFieldSubType.DateDots:
    case DataModelFieldSubType.DateDefault:
    case DataModelFieldSubType.DateReverse:
      return [BlocklyOutputType.String, CustomOutputType.DMFDate];

    case DataModelFieldSubType.NumberPhone:
    case DataModelFieldType.Number:
      return [BlocklyOutputType.Number, CustomOutputType.DMFNumber];

    default:
      return type;
  }
};

export interface ConstantVariable extends LabelValue, ById {}

export interface DummyBlock
  extends ById,
    Pick<
      Recovery,
      'withNullNextStatement' | 'withNullPreviousStatement' | 'withNullOutput'
    > {
  name?: string;
}

export interface Recovery {
  registerType: string;
  name: string;
  withNullNextStatement?: boolean;
  withNullPreviousStatement?: boolean;
  withNullOutput?: boolean;
}

const FIELD_TYPE = {
  image: 'field_image',
  input: 'input_value',
  dropdown: 'field_dropdown',
};

const BLOCKS_FIELDS_MAP = {
  [BLOCK_TYPE.SET_VARIABLE_VALUE_TO]: ['VARIABLE', 'VALUE'],
  [BLOCK_TYPE.SEND_SMS_TO]: ['SMS_RECIPIENT'],
  [BLOCK_TYPE.GET_FORM]: ['FORM_TO_GET'],
  [BLOCK_TYPE.INDEXING]: ['INDEX_FIELD', 'INDEX_FORM'],
  [BLOCK_TYPE.STEP_DELIMITER]: ['STEP_DELIMITER_NAME'],
  [BLOCK_TYPE.APPEND_TEXT]: ['APPEND_TO', 'APPEND_VALUE'],
  [BLOCK_TYPE.SHOW_DOCUMENT]: ['SHOW_DOCUMENT_VALUE'],
  [BLOCK_TYPE.SHOW_FORM]: ['SHOW_FORM_VALUE'],
  [BLOCK_TYPE.PLANE_SEND_EMAIL_TO]: [
    'PLANE_SEND_EMAIL_RECEIVER',
    'PLANE_SEND_EMAIL_SUBJECT',
    'PLANE_SEND_EMAIL_BODY',
  ],
  [BLOCK_TYPE.PLANE_SEND_SMS_TO]: [
    'PLANE_SEND_SMS_RECEIVER',
    'PLANE_SEND_SMS_BODY',
  ],
  [BLOCK_TYPE.FINISH_INSTANCE_WITH]: ['FINISH_INSTANCE_WITH'],
  [BLOCK_TYPE.SEND_FORM_WITH_SMS]: [
    'SEND_SMS_FORM_TO',
    'SEND_SMS_FORM_BODY',
    'SEND_SMS_FORM',
  ],
  [BLOCK_TYPE.CHECK_IN_TRUCK_MATE]: [
    'CHECK_IN_NUMBER',
    'CHECK_IN_DOC_TYPE',
    'CHECK_IN_RESULT',
  ],
  [BLOCK_TYPE.SEND_FILE_TO_OCR]: ['OCR_FILE'],
  [BLOCK_TYPE.SUB_STEP]: ['SUB_STEP_NAME'],
  [BLOCK_TYPE.SUB_STRING]: [
    'SUB_STRING_VALUE',
    'SUB_STRING_FROM',
    'SUB_STRING_TO',
  ],
  [BLOCK_TYPE.SEND_FORM_WITH_EMAIL_TEMPLATE]: [
    'SEND_EMAIL_FORM_TO',
    'SEND_EMAIL_TEMPLATE',
    'SEND_EMAIL_FORM',
  ],
  [BLOCK_TYPE.SEARCH_INSTANCES]: ['SEARCH_RESULT', 'SEARCH_RESULT_NUM'],
  [BLOCK_TYPE.UPDATE_INSTANCES]: ['SEARCH_RESULT'],
  [BLOCK_TYPE.SEND_FILE_TO_FTP]: ['FTP_FILE', 'FTP_FILE_NAME'],
};

const FIELD_INDEX = {
  first: 0,
  second: 1,
  third: 2,
  fourth: 3,
};

const DEFAULT_ACTION_BLOCK_DATA = {
  recovery: {
    withNullNextStatement: true,
    withNullPreviousStatement: true,
  },
};

export {
  EMPTY_STATE_IMG_CONFIG,
  CLASS_NAMES,
  TEXTS,
  ICONS_MAP,
  getDataModelFieldTooltip,
  getFieldName,
  getOutputByModelType,
  CustomOutputType,
  BlocklyOutputType,
  BLOCK_TYPE,
  EXTENSION,
  ExtensionKey,
  FIELD_TYPE,
  FIELD_INDEX,
  BLOCKS_FIELDS_MAP,
  DEFAULT_ACTION_BLOCK_DATA,
  REMOVED_STATIC_BLOCKS,
};
