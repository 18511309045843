import { AGREEMENTS } from 'pages/agreements/config';
import React, { Fragment } from 'react';

import { FreshdeskLink } from '../freshdeskLink';

export const AgreementsFragment = () => {
  const last = AGREEMENTS[AGREEMENTS.length - 1];

  return (
    <>
      {AGREEMENTS.slice(0, -1).map((agreement, idx, arr) => (
        <Fragment key={agreement.key}>
          <FreshdeskLink
            articleID={agreement.articleID}
            linkText={agreement.humanAgreementName}
          />
          {idx !== arr.length - 1 ? ', ' : ' '}
        </Fragment>
      ))}
      {'and '}
      <FreshdeskLink
        articleID={last.articleID}
        linkText={last.humanAgreementName}
      />
    </>
  );
};

export default AgreementsFragment;
