import React, { memo } from 'react';

import Logout from 'assets/icon/logout.svg';
import Shortcut from 'assets/icon/shortcut.svg';

import Spinner from 'components/atoms/spinner';
import Text from 'components/atoms/text';
import AgreementsFragment from 'components/molecules/privacyPolicy';
import LogOutModal from 'components/organisms/modal/logOut';
import ShortcutsModal from 'components/organisms/modal/shortcuts';
import Select from 'components/organisms/select';

import { MENU_ACCESSORS, TEXTS } from './config';
import sm from './styles.module.scss';
import { ProfileMenuProps } from './types';
import useProfileMenu from './useProfileMenu';

const ProfileMenu = ({ onItemClick, isMobile }: ProfileMenuProps) => {
  const {
    handleConfirmLogOut,
    isLogOutPending,
    info,
    currentTenantRole,
    normalizedTenants,
    handleChangeTenant,
    activeTenant,
    anyPending,
    handleSetAction,
    activeAction,
    handleResetActiveAction,
  } = useProfileMenu(onItemClick);

  return (
    <>
      {anyPending && <Spinner />}
      <LogOutModal
        disableButtons={isLogOutPending}
        isVisible={activeAction?.type === MENU_ACCESSORS.LOG_OUT}
        onReject={handleResetActiveAction}
        onAccept={handleConfirmLogOut}
      />
      <ShortcutsModal
        isVisible={activeAction?.type === MENU_ACCESSORS.SHORTCUTS}
        onClose={handleResetActiveAction}
      />
      <div className={sm.Container}>
        <div className={sm.Container_Info}>
          <p className={sm.Container_Info_Name}>
            <Text
              content={`${info?.firstName} ${info?.lastName}`}
              with20Font
              withSemiBoldWeight
            />
          </p>
          <Text content={currentTenantRole} />
          <div className={sm.Container_PrivacyPolicy}>
            <AgreementsFragment />
          </div>
        </div>
        <Select
          list={normalizedTenants}
          onSelectChange={handleChangeTenant}
          selected={activeTenant}
          withoutBorder
        />
        {!isMobile && (
          <>
            <hr className={sm.Container_Delimiter} />
            <button
              type="button"
              onClick={handleSetAction(MENU_ACCESSORS.SHORTCUTS)}
              className={sm.Container_Item}
            >
              <img
                className={sm.Container_Item_Icon}
                src={Shortcut}
                alt={TEXTS.SHORTCUTS}
              />
              <Text content={TEXTS.SHORTCUTS} />
            </button>
          </>
        )}

        <hr className={sm.Container_Delimiter} />
        <button
          className={sm.Container_Item}
          onClick={handleSetAction(MENU_ACCESSORS.LOG_OUT)}
        >
          <img
            className={sm.Container_Item_Icon_Wide}
            src={Logout}
            alt={TEXTS.ALT.PROFILE}
          />
          <Text content={TEXTS.LOG_OUT} withSemiBoldWeight with16Font />
        </button>
      </div>
    </>
  );
};

export default memo(ProfileMenu);
