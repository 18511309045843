import { useAppServices } from 'container/depContext';
import {
  AccountLinkingInfo,
  UserRepoActionKeys,
} from 'container/services/users/IUserRepo';
import { useCallback } from 'react';

import { useAuthCache } from 'api/cache';
import {
  ReadingCacheType,
  useReadAPIWithServices,
} from 'api/useAPIWithServices';

export function useAccountLinkingConfig(): {
  info?: AccountLinkingInfo;
  refresh: () => Promise<void>;
} {
  const { getUserRepo } = useAppServices();
  const { getCurrentUserInfo, getUserAccountLinkingState } = getUserRepo();

  const { loggedIn } = useAuthCache();

  const { data: user } = useReadAPIWithServices(
    [UserRepoActionKeys.GetCurrentUserInfo, loggedIn ? 'loggedIn' : undefined], // only try loading user data if we're logged in
    getCurrentUserInfo,
    { cacheType: ReadingCacheType.ExecutionMainEntity }
  );

  const { data: accountLinkingInfo, mutate: refresh } = useReadAPIWithServices(
    [UserRepoActionKeys.GetAccountLinkingState, user],
    () => getUserAccountLinkingState(user!),
    { cacheType: ReadingCacheType.ExecutionMainEntity }
  );

  return {
    info: accountLinkingInfo,
    refresh: useCallback(async () => {
      await refresh();
    }, [refresh]),
  };
}
